import { IS_NODE_DEV } from './dewantara-constants';

export namespace APIRoutes {
  export const API_ROUTE: string = IS_NODE_DEV
    ? process.env.REACT_APP_API_URL
    : window._env_.REACT_APP_API_URL;
  export const AUTH_ROUTE: string = IS_NODE_DEV
    ? process.env.REACT_APP_AUTH_URL
    : window._env_.REACT_APP_AUTH_URL;
  export const MINIO_ROUTE: string = IS_NODE_DEV
    ? process.env.REACT_APP_MINIO_URL
    : window._env_.REACT_APP_MINIO_URL;

  export const PATH = {
    REGISTER: 'register',
    LOGIN: 'login',
    ME: 'me',
    REFRESH: 'refresh',
    VERIFY_EMAIL: 'verify/email',
    CHILD: 'child',
    CHILDREN: 'children',
    CHILDREN_USER: 'children/user',
    USER_PRODUCTS: 'user-products',
    RECENTLY_VIEWED: 'recently-viewed',
    SCREEN_TIME: 'screen-times',
    STATS: 'stats',
    PRODUCTS: 'products',
    PUBLISHED_PRODUCTS: 'products/published',
    PRODUCT_RESOURCES: 'product-resources',
    CHILD_PROGRESSES: 'child-progresses',
  };
}
