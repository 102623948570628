import { DewantaraInputProps } from '@dewantara-types/props';
import { Input } from '@material-tailwind/react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { INPUT_STYLES } from 'dewantara-constants';
import React, { useState } from 'react';

export function DewantaraInputPassword(props: DewantaraInputProps): React.ReactElement {
  const { className, step, value, placeholder, inputHook } = props;

  const textSizeClass = INPUT_STYLES.TEXT_SIZE_CLASSES;
  const inputPaddingClass = INPUT_STYLES.PADDING_CLASSES;

  const [isVisible, setIsVisible] = useState<boolean>(false);

  function inputChangeHandler(e: Events.InputChangeEvent) {
    inputHook?.inputChangeHandler(e.target.value.toString());
  }

  function inputBlurHandler(e: Events.InputBlurEvent) {
    inputHook?.inputBlurHandler();
  }

  return (
    <div className="w-full">
      <Input
        type={isVisible ? 'text' : 'password'}
        step={step}
        value={value}
        placeholder={placeholder}
        className={`focus:!border-d-blue-primary ${textSizeClass} ${inputPaddingClass} ${className}`}
        labelProps={{
          className: 'before:content-none after:content-none',
        }}
        containerProps={{
          className:
            '!min-w-0 !mr-2',
        }}
        icon={
          isVisible ? (
            <Visibility onClick={() => setIsVisible(false)} />
          ) : (
            <VisibilityOff onClick={() => setIsVisible(true)} />
          )
        }
        onChange={inputChangeHandler}
        onBlur={inputBlurHandler}
      />
    </div>
  );
}

export default DewantaraInputPassword;
