export const IS_NODE_DEV: boolean =
  process.env.NODE_ENV && process.env.NODE_ENV === 'development';

export namespace AppStatus {
  export const IS_MAINTENANCE: number = parseInt(
    IS_NODE_DEV
      ? process.env.REACT_APP_IS_MAINTENANCE
      : window._env_.REACT_APP_IS_MAINTENANCE ?? '0'
  );
  export const IS_DEV: number = parseInt(
    IS_NODE_DEV ? process.env.REACT_APP_IS_DEV : window._env_.REACT_APP_IS_DEV ?? '0'
  );
}

export enum PAGE_TITLE {
  LOGIN = 'Login',
  REGISTER = 'Daftar',
  DASHBOARD = 'Beranda',
  STORE = 'Toko',
  USER = 'Pengguna',
  LIBRARY = 'Perpustakaan',
}

export namespace GOOGLE {
  export const ANALYTICS_ID: string = IS_NODE_DEV
    ? process.env.REACT_APP_GA_ID
    : window._env_.REACT_APP_GA_ID ?? '';

  export const FEEDBACK_FORM_LINK: string = IS_NODE_DEV
    ? process.env.REACT_APP_FEEDBACK_FORM
    : window._env_.REACT_APP_FEEDBACK_FORM ?? '';
}

export const PUBLIC_PATH: string = IS_NODE_DEV
  ? process.env.PUBLIC_URL
  : window._env_.PUBLIC_URL ?? '';
export const ASSETS_PATH: string = `${PUBLIC_PATH}/assets`;

export const SCREEN_TIME_DANGER_VALUE: number = 45; // In minutes
export const SCREEN_TIME_WARNING_VALUE: number = 35; // In minutes
export const SCREEN_TIME_TOOLTIP_MESSAGE: string =
  'Yuk, pastikan waktu anak di depan layar tidak melebihi 45 menit untuk menjaga kesehatan matanya dengan baik!';

export enum GENDERS {
  BOY = 'BOY',
  GIRL = 'GIRL',
}

export enum ROLES {
  USER = 'USER',
  DEV = 'DEV',
}

export const LOCAL_ACCESS_TOKEN: string = 'd_at';
export const LOCAL_REFRESH_TOKEN: string = 'drt';
export const LOCAL_PRODUCT_MODULES: string = 'dpm';
export const LOCAL_SETTING: string = 'ds';
export const LOCAL_FEEDBACK_TRIGGER1: string = 'ft1';

export const MAX_FILE_SIZE: number = 1000000;
