import React, { useEffect } from 'react';
import { Props } from '@dewantara-types/props';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@dewantara-types/store';
import { useDispatch } from 'react-redux';
import { authActions } from 'store/slices/auth-slice';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { browserActions } from 'store/slices/browser-slice';
import useGoogleManager from 'hooks/use-google-manager';

function ScreenLayout(props: Props): React.ReactElement {
  const location = useLocation();

  const googleManager = useGoogleManager();

  const dispatch = useDispatch<AppDispatch>();
  const { user, accessToken, refreshToken } = useSelector(
    (state: RootState) => state.auth
  );
  const { currentPath } = useSelector((state: RootState) => state.browser);

  useEffect(() => {
    if (!googleManager.isInitiated) {
      const initGoogleTimeout = setTimeout(
        () => googleManager.init(user ?? undefined),
        1000
      );
      return () => clearTimeout(initGoogleTimeout);
    }
  });

  useEffect(() => {
    if (accessToken) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken.token;
    } else {
      axios.defaults.headers.common['Authorization'] = null;
    }
  }, [accessToken]);

  useEffect(() => {
    if (refreshToken && refreshToken.expiresIn <= Date.now())
      dispatch(authActions.clearAuth());
  }, [refreshToken, dispatch]);

  useEffect(() => {
    if (!currentPath || currentPath !== location.pathname) {
      dispatch(browserActions.setCurrentPath(location.pathname));
    }
  }, [currentPath, location.pathname, dispatch]);

  return (
    <div className="max-w-screen max-h-screen overflow-auto no-scrollbar">
      {props.children}
    </div>
  );
}

export default ScreenLayout;
