import { DewantaraInputSingleFileProps } from '@dewantara-types/props';
import { CloudUploadRounded } from '@mui/icons-material';
import Compressor from 'compressorjs';
import { MAX_FILE_SIZE } from 'dewantara-constants';
import { ErrorDev } from 'logging/Logging';
import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';

export function DewantaraInputSingleFile(
  props: DewantaraInputSingleFileProps
): React.ReactElement {
  const { className, isAudio = false, content, onFileChange } = props;

  const accept: Accept = isAudio ? { 'audio/*': [], 'video/mp4': [] } : { 'image/*': [] };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: setFiles,
    multiple: false,
    accept: accept,
  });

  function setFiles(acceptedFiles: File[]) {
    function compressFile(file: File | Blob, quality: number) {
      new Compressor(file, {
        quality: quality,
        success: (result: File) => {
          if (result.size > MAX_FILE_SIZE) {
            compressFile(file, quality - 0.1);
          } else {
            const filePath = URL.createObjectURL(result);
            onFileChange && onFileChange({ path: filePath, file: result });
          }
        },
        error: (err: Error) => {
          ErrorDev(err.message);
        },
      });
    }

    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        if (!file) continue;

        if (!isAudio && file.size > MAX_FILE_SIZE) {
          compressFile(file, 0.8);
        } else {
          const filePath = URL.createObjectURL(file);
          onFileChange && onFileChange({ path: filePath, file: file });
        }
      }
    }
  }

  return (
    <div
      {...getRootProps({
        className: `px-2.5 py-4 bg-gray-50 border-2 border-dashed border-gray-300 rounded-lg ${className}`,
      })}
    >
      <label
        className="w-full h-full flex flex-col items-center justify-center gap-2.5 cursor-pointer"
        htmlFor="dropzone-file"
      >
        <CloudUploadRounded className="!h-10 !w-10 text-gray-500" />
        {content}
        <input
          {...getInputProps({
            className: 'hidden',
            id: 'dropzone-file',
          })}
        />
      </label>
    </div>
  );
}

export default DewantaraInputSingleFile;
