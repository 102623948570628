import { AppStatus } from 'dewantara-constants';

function Log(message: any, color?: string) {
  if (color) console.log(`%c${message}`, `color: ${color}; font-size: 16px;`);
  else console.log(message);
}

function Error(message: any) {
  console.error(`%c${message}`, 'font-size: 12px;');
}

function LogDev(message: any, color?: string) {
  if (AppStatus.IS_DEV) {
    Log(message, color);
  }
}

function ErrorDev(message: any) {
  if (AppStatus.IS_DEV) {
    Error(message);
  }
}

export { Log, Error, LogDev, ErrorDev };
