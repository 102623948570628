import {
  ProductModule,
  ProductModuleType,
} from '@dewantara-types/models/product-modules';
import { ProductBuilderState } from '@dewantara-types/store';
import { PRD_MODULE_PREFIX } from 'dewantara-constants';
import { ErrorDev } from 'logging/Logging';
import { v4 as UUIDv4 } from 'uuid';

export function buildProductBuilder(
  jsonString: string,
  errorHandler?: () => void
): ProductBuilderState {
  try {
    const parsed = JSON.parse(jsonString) as ProductBuilderState;
    return parsed;
  } catch (e) {
    ErrorDev('Failed to parse product builder JSON: ' + e);
    errorHandler && errorHandler();
  }
  return { productModules: [] };
}

export function buildProductModules(
  jsonString?: string,
  errorHandler?: () => void
): ProductModule[] {
  if (!jsonString || jsonString.length < 1) jsonString = '{}';

  try {
    const parsed = JSON.parse(jsonString);
    if (parsed.length > 0) {
      if (
        typeof parsed[0].code === 'string' &&
        typeof parsed[0].page === 'number' &&
        typeof parsed[0].name === 'string' &&
        typeof parsed[0].type === 'string'
      ) {
        return parsed as ProductModule[];
      } else {
        throw new Error('Invalid format: for ProductModule.');
      }
    }
  } catch (error) {
    ErrorDev('Failed to parse product payload JSON: ' + error);
    errorHandler && errorHandler();
  }
  return [];
}

export function createProductModule(
  page: number,
  type: ProductModuleType
): ProductModule {
  const date = new Date();

  return {
    code: PRD_MODULE_PREFIX + UUIDv4(),
    name: 'Halaman baru',
    type: type,
    objects: [],
    page: page,
    answers: [],
    createdAt: date,
    updatedAt: date,
  };
}

export function duplicateProductModule(productModule: ProductModule): ProductModule {
  return {
    code: PRD_MODULE_PREFIX + UUIDv4(),
    name: productModule.name + ' copy',
    type: productModule.type,
    objects: productModule.objects,
    page: productModule.page + 1,
    answers: productModule.answers,
    createdAt: productModule.createdAt,
    updatedAt: new Date(),
  };
}

export function sortProductModules(productModule: ProductModule[]): ProductModule[] {
  return productModule.sort(
    (obj1: ProductModule, obj2: ProductModule) => obj1.page - obj2.page
  );
}
