import ApplicationLayout from 'layouts/ApplicationLayout';
import ScreenLayout from 'layouts/ScreenLayout';
import PortalLayout from 'layouts/PortalLayout';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ApplicationRoutes } from 'dewantara-constants';
import { Typography } from '@material-tailwind/react';

const DashboardPage = React.lazy(() => import('./pages/Dashboard'));
const LibraryPage = React.lazy(() => import('./pages/Library'));
const StorePage = React.lazy(() => import('./pages/Store'));
const UserPage = React.lazy(() => import('./pages/User'));
const ProductDetailPage = React.lazy(() => import('./pages/ProductDetail'));
const ProductDetailInfoPage = React.lazy(() => import('./pages/ProductDetailInfo'));
const ProductDetailPagesPage = React.lazy(() => import('./pages/ProductDetailPages'));
const PortalPage = React.lazy(() => import('./pages/Portal'));
const AddProductPage = React.lazy(() => import('./pages/AddProduct'));
const EditProductPage = React.lazy(() => import('./pages/EditProduct'));
const PageBuilderPage = React.lazy(() => import('./pages/PageBuilder'));

const AuthLayout = React.lazy(() => import('./layouts/AuthLayout'));

function App(): React.ReactElement {
  return (
    <ScreenLayout>
      <Suspense fallback={<Typography>Loading</Typography>}>
        <Routes>
          <Route element={<ApplicationLayout />}>
            <Route index element={<DashboardPage />} />
            <Route path={ApplicationRoutes.DASHBOARD_ROUTE} element={<DashboardPage />} />
            <Route path={ApplicationRoutes.LIBRARY_ROUTE} element={<LibraryPage />} />
            <Route path={ApplicationRoutes.STORE_ROUTE} element={<StorePage />} />
            <Route path={ApplicationRoutes.USER_ROUTE} element={<UserPage />} />
            <Route
              path={ApplicationRoutes.PRODUCT_DETAIL_ROUTE}
              element={<ProductDetailPage />}
            >
              <Route index element={<ProductDetailInfoPage />} />
              <Route
                path={ApplicationRoutes.PRODUCT_DETAIL_PAGE_ROUTE}
                element={<ProductDetailPagesPage />}
              />
            </Route>
          </Route>

          <Route
            path={ApplicationRoutes.AUTH_ROUTE + ApplicationRoutes.CATCH_ALL_ROUTE}
            element={<AuthLayout />}
          />

          <Route path={ApplicationRoutes.PORTAL_ROUTE} element={<PortalLayout />}>
            <Route index element={<PortalPage />} />
            <Route
              path={ApplicationRoutes.ADD_PRODUCT_ROUTE}
              element={<AddProductPage />}
            />
            <Route
              path={ApplicationRoutes.EDIT_PRODUCT_ROUTE}
              element={<EditProductPage />}
            />
            <Route
              path={ApplicationRoutes.PAGE_BUILDER_ROUTE}
              element={<PageBuilderPage />}
            />
          </Route>

          <Route
            path={`${ApplicationRoutes.CATCH_ALL_ROUTE}`}
            element={<Navigate to={ApplicationRoutes.ROOT_ROUTE} />}
          />
        </Routes>
      </Suspense>
    </ScreenLayout>
  );
}

export default App;
