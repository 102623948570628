import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from '@dewantara-types/store';
import { useSelector } from 'react-redux';
import { ApplicationRoutes } from 'dewantara-constants';
import { useDispatch } from 'react-redux';
import { fetchScreenTime, fetchUser } from 'store/actions';
import { NavigationBar } from 'components/layouts';
import FeedbackDialog from 'components/dialogs/FeedbackDialog';
import { uiActions } from 'store/slices/ui-slice';
import useGoogleManager from 'hooks/use-google-manager';

function ApplicationLayout(): React.ReactElement {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const googleManager = useGoogleManager();

  const { screenTime, openFeedbackDialogTrigger1, openFeedbackDialogTrigger2 } =
    useSelector((state: RootState) => state.ui);
  const { user, accessToken, activeChild } = useSelector(
    (state: RootState) => state.auth
  );
  const { prevPath, currentPath } = useSelector((state: RootState) => state.browser);

  useEffect(() => {
    if (accessToken && !user) dispatch(fetchUser());
  });

  useEffect(() => {
    if (screenTime?.totalTime === -1 && activeChild && activeChild.code) {
      dispatch(fetchScreenTime(activeChild.code));
    }
  }, [screenTime, activeChild, dispatch]);

  useEffect(() => {
    if (
      prevPath &&
      currentPath &&
      currentPath !== prevPath &&
      prevPath.endsWith(ApplicationRoutes.PRODUCT_DETAIL_PAGE_ROUTE)
    )
      dispatch(uiActions.setFeedbackDialog({ trigger2: true }));
  }, [currentPath, prevPath, screenTime, dispatch]);

  useEffect(() => {
    if (user && user.code !== googleManager.userId) {
      const googleTimeout = setTimeout(() => googleManager.setUser(user), 1000);
      return () => clearTimeout(googleTimeout);
    }
  });

  return (
    <div className="w-full h-full min-h-screen flex flex-col-reverse lg:flex-col items-center">
      <FeedbackDialog open={openFeedbackDialogTrigger1 && openFeedbackDialogTrigger2} />
      <NavigationBar className="z-10" />
      <div className="w-full">
        <Outlet key={location.key} />
      </div>
    </div>
  );
}

export default ApplicationLayout;
