import { IUseGoogleManager } from '@dewantara-types/hooks';
import { User } from '@dewantara-types/models';
import { ProductModule } from '@dewantara-types/models/product-modules';
import { AppDispatch, RootState } from '@dewantara-types/store';
import { GOOGLE } from 'dewantara-constants';
import { LogDev } from 'logging/Logging';
import { useRef } from 'react';
import GA4 from 'react-ga4';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { gaActions } from 'store/slices/ga-slice';

const useGoogleManager = (): IUseGoogleManager => {
  const dispatch = useDispatch<AppDispatch>();
  const { isInitiated, userId } = useSelector((state: RootState) => state.ga);

  const LOG_COLOR: string = 'cyan';

  const loggedPageAt = useRef<number>(-1);

  function init(user?: User) {
    LogDev('Init React GA', LOG_COLOR);
    if (user) GA4.initialize(GOOGLE.ANALYTICS_ID, { gaOptions: { userId: user.code } });
    else GA4.initialize(GOOGLE.ANALYTICS_ID);
    dispatch(gaActions.setInitiateGoogle({ status: true, userId: user?.code }));
  }

  function setUser(user: User) {
    LogDev(`Set GA user id: ${user.code}`, 'cyan');
    GA4.set({
      userId: user.code,
    });
    gaActions.setGAUser(user.code ?? '');
  }

  function clearUser() {
    LogDev('Clear GA user id', LOG_COLOR);
    GA4.set({ userId: null });
    gaActions.clearUser();
  }

  function logPageEvent(page: string, route: string) {
    LogDev(`Log GA page view: ${page}`, LOG_COLOR);
    loggedPageAt.current = Date.now();

    GA4.event('page_view', {
      category: 'Page view',
      action: `View ${page}`,
      label: `Log ${page}`,
      value: route,
      nonInteraction: true,
    });
  }

  function sendPageDurationEvent(page: string) {
    LogDev(`Log GA page session: ${page}`, LOG_COLOR);
    const duration =
      loggedPageAt.current < 0
        ? loggedPageAt.current
        : (Date.now() - loggedPageAt.current) / 1000;

    GA4.event('page_session', {
      category: 'Page session',
      action: 'duration',
      label: `Time spent on ${page}`,
      value: duration,
      nonInteraction: true,
    });
  }

  function startProductModule(module: ProductModule) {
    const typeStr: string = module.type.replaceAll('-', ' ');
    LogDev(`Log GA product module start: ${module.code}`, LOG_COLOR);
    GA4.event('level_start', {
      levelName: `Level ${module.code}`,
      category: typeStr,
    });
    GA4.event('select_content', {
      contentType: `Module ${typeStr}`,
      contentId: module.code,
    });
  }

  function endProductModule(module: ProductModule, success: boolean, duration: number) {
    const typeStr: string = module.type.replaceAll('-', ' ');
    LogDev(`Log GA product module end: ${module.code}`, LOG_COLOR);
    GA4.event('level_end', {
      levelName: `Level ${module.code}`,
      success: success,
      category: typeStr,
    });
    GA4.event('level_session', {
      levelName: `Level ${module.code}`,
      category: typeStr,
      value: duration,
    });
  }

  return {
    isInitiated: isInitiated,
    userId: userId,
    init,
    setUser,
    clearUser,
    logPageEvent,
    sendPageDurationEvent,
    startProductModule,
    endProductModule,
  };
};

export default useGoogleManager;
