import React from 'react';
import { ASSETS_PATH } from 'dewantara-constants';
import { useDispatch } from 'react-redux';
import { uiActions } from 'store/slices/ui-slice';
import { DewantaraDialog } from 'components/base';
import { FeedbackDialogProps } from '@dewantara-types/props/components/dialogs/feedback-dialog-props';
import { GOOGLE } from 'dewantara-constants';

export function FeedbackDialog(props: FeedbackDialogProps): React.ReactElement {
  const { open, className } = props;
  const dispatch = useDispatch();

  const dialogHandler = () => {
    dispatch(uiActions.setFeedbackDialog({ trigger2: false }));
  };
  const goToFeedbackHandler = () => {
    window.open(GOOGLE.FEEDBACK_FORM_LINK, '_blank');
    dispatch(uiActions.setFeedbackDialog({ trigger1: false, trigger2: false }));
  };

  let imgPath = `${ASSETS_PATH}/img/feedback-dialog.png`;
  let imgAlt = 'feedback-dialog';

  return (
    <DewantaraDialog
      className={className}
      open={open}
      imgSrc={imgPath}
      imgAlt={imgAlt}
      message="Ceritakan pendapat Anda! Feedback dari Anda membantu kami menciptakan pengalaman yang lebih baik."
      onHandleDialog={dialogHandler}
      primaryButtonText="Yuk"
      primaryButtonAction={goToFeedbackHandler}
      secondaryButtonText={'Nanti saja'}
      secondaryButtonAction={dialogHandler}
    />
  );
}

export default FeedbackDialog;
