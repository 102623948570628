import { GAState, GoogleUserAction, InitGoogleAction } from '@dewantara-types/store';
import { createSlice } from '@reduxjs/toolkit';

// Initial state
const gaInitialState: GAState = {
  isInitiated: false,
  userId: null,
};

export const gaSlice = createSlice({
  name: 'ga',
  initialState: gaInitialState,
  reducers: {
    setInitiateGoogle(state: GAState, action: InitGoogleAction) {
      const { status, userId } = action.payload;
      state.isInitiated = status;
      if (userId) state.userId = userId;
    },
    setGAUser(state: GAState, action: GoogleUserAction) {
      state.userId = action.payload;
    },
    clearUser(state: GAState) {
      state.userId = null;
    },
  },
});

export const gaActions = gaSlice.actions;
